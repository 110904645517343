import React from 'react';
import { string } from 'prop-types';

const propTypes = {
  spinColor: string.isRequired,
};

const Spinner = ({ spinColor }) => (
  <div className="spinner">
    <div className="spin" style={{ '--spin-color': spinColor }}>
      Loading...
    </div>
  </div>
);

Spinner.propTypes = propTypes;

// Specifies the default values for props:
Spinner.defaultProps = {
  spinColor: '#fff',
};

export default Spinner;
