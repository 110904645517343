import React, { useState, useEffect } from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';

import { useQuery } from 'react-query';

import NewsItem from '../NewsItem';
import Spinner from '../Spinner';
import { object, func } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

import { useNewsFetch } from '../../hooks/useNewsFetch';

const LatestNews = (props) => {
  const { sitecoreContext, t } = props;

  // Get total news count
  const fetchTotalNews = async () => {
    let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${sitecoreContext.language}&$filter=TemplateName eq 'News Article'&$select=Name`;

    const request = await fetch(url);

    let result = await request.json();
    result = result.value.length;
    return result;
  };

  let totalNews = useQuery('totalNews', fetchTotalNews, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  totalNews = totalNews.data;

  const { latestNews, loading, setIsLoadingMore } = useNewsFetch(config, sitecoreContext.language);

  return (
    <div className="container-bleed container-bleed__has-curved-bg">
      <div className="container">
        <div className="latest-news latest-news__page">
          <div className="latest-news__wrapper">
            {latestNews.map((newsItem) => (
              <NewsItem data={newsItem.FieldValues} key={newsItem.Id} url={newsItem.Path} />
            ))}
          </div>

          {loading && <Spinner />}

          {latestNews.length < totalNews && !loading && (
            <div className="latest-news__load-more">
              <button className="btn btn-primary" onClick={() => setIsLoadingMore(true)}>
                <span>{t('LoadMore')}</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

LatestNews.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(LatestNews));
