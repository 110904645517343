import React from 'react';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const CommonInnerPages = ({ rendering }) => {
  return (
    <>
      <div className="Inner-pages">
        {/* Collapse area with plus icon for mobile devices */}
        <div className="toggle-border">
          <Placeholder name="left-inner-col" rendering={rendering} />
        </div>
        <div className="Inner-pages__details">
          <div className="scroll-container">
            <Placeholder name="right-inner-col" rendering={rendering} />
          </div>
        </div>
      </div>
    </>
  );
};

export default withSitecoreContext()(CommonInnerPages);
