import React, { useState, useEffect } from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';
import { Link, Element, Events } from 'react-scroll';
import useMatchMedia from '../../hooks/useMatchMedia';

const InnerPagesNavigation = (props) => {
  let contentItems = props.fields.items.filter((d) => d.fields.content);
  let navItems = contentItems.filter((d) => d.fields.heading.value !== '');
  if (!navItems[0]) return <div className="inner-nav"></div>;
  const [activeLink, setActiveLink] = useState(navItems[0].id);
  const [clickedLink, setClickedLink] = useState(null);
  const isMobile = useMatchMedia('(min-width: 500px)');
  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleSetActiveLink = (id) => {
    setActiveLink(id);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(isMobile);
      setIsCollapsed(false);
    };
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
    }
    // Select the element with the specific class you want to check
    const targetElement = document.querySelector('.Inner-pages');

    // Check if the specific class is present in the target element's classList
    const hasSpecificClass = targetElement && targetElement.classList.contains('Inner-pages');

    // If the class is present, add your desired CSS class to another element
    if (hasSpecificClass) {
      const elementToModify = document.querySelector('.header-main ');
      elementToModify && elementToModify.classList.add('Inner-pages__videoHero-InnerPages');
    }

    const handleScroll = () => {
      const scrollY = window.scrollY;

      const activeSection = navItems.find(({ id }) => {
        const element = document.getElementById('{' + id.toUpperCase() + '}');
        if (element) {
          const offsetTop = element.offsetTop;
          const offsetBottom = offsetTop + element.offsetHeight;
          return scrollY >= offsetTop - 50 && scrollY < offsetBottom;
        }
        return false;
      });

      if (activeSection) {
        setActiveLink(activeSection.id);
      }
    };
    if (typeof document !== 'undefined') {
      document.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof document !== 'undefined') {
        document.removeEventListener('scroll', handleScroll);
      }
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [navItems]);

  const handleLinkClick = (id) => {
    setClickedLink(id);
    setActiveLink(id);

    // Optional: Scroll to the clicked section after setting the active link
    const offset = -50;
    const duration = 500;
    const element = document.getElementById('{' + id.toUpperCase() + '}');
    if (element) {
      const offsetTop = element.offsetTop + offset;
      window.scroll({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };
  return (
    <>
      <Text tag="h5" className="toggle-title" field={props.fields.title} />
      {!isCollapsed && (
        <>
          <div className="collapse-toggle" onClick={handleToggleCollapse}>
            <span className="toggle-text">{navItems[0].fields.heading.value}</span>
            <span className="toggle-icon">{!isCollapsed ? '-' : ''}</span>
          </div>
        </>
      )}
      {isCollapsed ? (
        <div className="collapse-toggle" onClick={handleToggleCollapse}>
          <span className="toggle-text">{navItems[0].fields.heading.value}</span>
          <span className="toggle-icon">{isCollapsed ? '+' : '-'}</span>
        </div>
      ) : (
        <div className="Inner-pages__navigation">
          {navItems.map(({ id, fields }) => (
            <div key={id}>
              <Link
                to={id}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
                onSetActive={() => handleSetActiveLink(id)}
                onClick={() => handleLinkClick(id)} // Set active link when clicked
                style={{ color: clickedLink === id ? '#47AD42' : '#414855' }} // Apply green color to the clicked link
              >
                <div className="Inner-pages__navigation--list">{fields.heading.value}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default withSitecoreContext()(InnerPagesNavigation);
