import React, { useState } from 'react';
// import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';

import moment from 'moment';

import { object, func } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

const FeaturedNews = (props) => {
  const { sitecoreContext, t } = props;

  const [featuredArticle, setFeaturedArticle] = useState(
    sitecoreContext.route.fields['Featured Gallery']
  );

  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }

  return (
    <div className="featured-news">
      <div className="featured-news__photo">
        <img
          src={featuredArticle.fields.Image.value.src || Constants.DEFAULT_IMAGE_URL}
          alt={featuredArticle.fields.Image.value.alt || Constants.DEFAULT_TITLE}
        />
      </div>
      <div className="container">
        <div className="featured-news__content">
          <div className="featured-news__content-wrapper">
            {featuredNewsIcon}
            <div className="featured-news__date">
              {moment(featuredArticle.fields.Date.value).format('DD MMMM YYYY')}
            </div>
            <h1>{featuredArticle.fields.Title.value}</h1>
            <p>{featuredArticle.fields.Description.value}</p>
            <Link
              to={`/${sitecoreContext.language}${featuredArticle.url}`}
              className="btn btn-hero"
            >
              <span>{t('ReadTheArticle')}</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

FeaturedNews.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(FeaturedNews));

const featuredNewsIcon = (
  <svg
    className="icon"
    width="126"
    height="159"
    viewBox="0 0 126 159"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M123.66 29.3078C119.44 10.4878 99.32 -5.9122 80.04 2.0678C49.85 16.6878 66.79 51.8578 51.7 69.6678C49.26 72.1678 46.18 65.7778 42.38 69.5678C37.4 74.8378 42 84.7378 38.01 90.2778C32.44 97.0678 21.74 87.0078 21.51 100.038C22.01 120.598 24.66 151.698 0 158.688C5.79 159.108 11.7 158.888 17.29 158.298C37.43 145.738 72.77 101.398 80.28 27.3278C99.11 37.6478 98.35 65.2978 102.13 66.0778C107.8 67.2578 112.63 61.8878 114.07 69.9678C115.11 75.7978 109.48 93.5378 96.57 111.438C86.58 125.288 71.78 138.098 61.64 145.658C102.72 124.018 133.11 75.6978 123.65 29.2978L123.66 29.3078Z"
      fill="url(#paint0_linear_1079_3494)"
    />
    <path
      d="M114.08 69.9678C112.65 61.8778 107.81 67.2578 102.14 66.0778C98.36 65.2978 99.12 37.6578 80.29 27.3278C72.78 101.398 37.44 145.738 17.3 158.298C33.56 156.638 47.1201 152.028 47.1201 152.028C52.0801 150.288 56.9401 148.148 61.6501 145.668C71.7801 138.098 86.58 125.298 96.58 111.448C109.49 93.5478 115.12 75.8078 114.08 69.9778V69.9678Z"
      fill="url(#paint1_linear_1079_3494)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1079_3494"
        x1="20.1825"
        y1="139.49"
        x2="90.8423"
        y2="-28.767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D00D4F" />
        <stop offset="0.05" stopColor="#D2144E" />
        <stop offset="0.35" stopColor="#DE3F4A" />
        <stop offset="0.62" stopColor="#E75F47" />
        <stop offset="0.84" stopColor="#ED7245" />
        <stop offset="1" stopColor="#EF7945" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1079_3494"
        x1="29.2754"
        y1="135.704"
        x2="85.1846"
        y2="2.57094"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E2737C" />
        <stop offset="1" stopColor="#F49F7D" />
      </linearGradient>
    </defs>
  </svg>
);
