import React, { useEffect, useRef } from 'react';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { bool, object, shape } from 'prop-types';
import { useLocation } from 'react-router-dom';

const propTypes = {
  fields: shape({
    video: object.isRequired,
    mainIcon: object.isRequired,
    title: object.isRequired,
    subtitle: object.isRequired,
    description: object.isRequired,
    scrollIcon: object.isRequired,
    IsInnerPage: object.isRequired,
    useImage: object.isRequired,
    HeroImage: object.isRequired,
  }),
};

const HeroVideo = ({ fields }) => {
  const {
    video,
    mainIcon,
    title,
    subtitle,
    description,
    scrollIcon,
    IsInnerPage,
    useImage,
    HeroImage,
  } = fields;

  const ref = useRef(null);
  const sectionClass = IsInnerPage.value ? 'header-main inner-hero' : 'header-main';

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // This to add class to body that control some styles for the component
  let location = useLocation();

  useEffect(() => {
    location = location.pathname.split('/');
    let bodyClass = location[location.length - 1];
    if (bodyClass === '' || bodyClass === 'en' || bodyClass === 'ar') {
      bodyClass = 'homepage';
    } else {
      bodyClass = bodyClass.toLowerCase().replace(' ', '-');
    }
    document.body.classList.add(bodyClass);

    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, []);

  return (
    <section className={sectionClass}>
      {useImage.value || video.value.href === '' ? (
        <div className="heroimage__wrapper">
          <div className="heroimage__image">
            <img src={HeroImage.value.src} alt={title.value} />
          </div>
        </div>
      ) : (
        <video loop autoPlay muted preload="auto" playsInline>
          <source src={video.value.href} type="video/mp4" />
        </video>
      )}

      <div className="container">
        <div className="content">
          <Image
            className="content__img"
            media={mainIcon}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="1250"
            data-aos-duration="1000"
          />
          <Text
            className="content__welcome"
            tag="div"
            field={title}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="750"
            data-aos-duration="1000"
          />
          <Text
            className="content__title"
            tag="h2"
            field={subtitle}
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="850"
            data-aos-duration="1000"
          />
          <div
            className="content__descHero"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="950"
            data-aos-duration="1000"
          >
            <RichText field={description} />
          </div>
        </div>
      </div>
      <Image className="icon__down" media={scrollIcon} onClick={handleClick} />
      <div ref={ref}></div>
    </section>
  );
};

HeroVideo.propTypes = propTypes;

export default HeroVideo;
