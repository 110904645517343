import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useRef, useState } from 'react';
import useMatchMedia from '../../hooks/useMatchMedia';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const StackedCTA = ({ fields }) => {
  const stackedData = fields.items;
  const sectionWrapper = useRef(null);
  const sectionCursor = useRef(null);
  const [activeCTA, setActiveCTA] = useState(stackedData[0].id);

  // matches bp-large-min in breakpoints.scss
  const isDesktop = useMatchMedia('(min-width: 1024px)');

  const handleActiveCTA = (id, event) => {
    const element = sectionWrapper.current;
    // pause all videos in component
    element.querySelectorAll('video').forEach((vid) => vid.pause());
    setActiveCTA(id);
    // play active CTA video
    event.target.querySelectorAll('video').forEach((vid) => vid.play());
  };

  const handleActiveAccordion = (active) => {
    const element = sectionWrapper.current;
    // pause all videos in component
    element.querySelectorAll('video').forEach((vid) => vid.pause());

    const activeButton = document.getElementById(`accordion__heading-${active[0]}`);
    // set ActiveCTA
    setActiveCTA(activeButton.parentElement.id);

    // play active CTA video
    activeButton
      .closest('.accordion__item')
      .querySelectorAll('video')
      .forEach((vid) => vid.play());
  };

  useEffect(() => {
    if (isDesktop) {
      const element = sectionWrapper.current;
      const handleCursor = (event) => {
        const { clientX, clientY } = event;

        const rect = sectionWrapper.current.getBoundingClientRect();
        const mouseX = clientX;
        const mouseY = clientY;

        sectionCursor.current.style.left = `${mouseX - rect.left - 52}px`;
        sectionCursor.current.style.top = `${mouseY - rect.top - 52}px`;
      };

      element.addEventListener('mousemove', handleCursor);

      return () => {
        element.removeEventListener('mousemove', handleCursor);
      };
    }
  }, [isDesktop]);

  const renderDesktop = (
    <>
      {stackedData.map((item) => (
        <div
          className={`stacked-cta__item  ${activeCTA === item.id ? 'active' : ''}`}
          key={item.id}
          onClick={(e) => handleActiveCTA(item.id, e)}
        >
          <video autoPlay={activeCTA === item.id} loop muted preload="auto" playsInline>
            <source src={item.fields.Video.value.href} type="video/mp4" />
          </video>
        </div>
      ))}
      <div className="stacked-cta__cursor" ref={sectionCursor}></div>

      {/* Render Text for active stack */}
      {stackedData.map((item) => (
        <div
          key={item.id}
          className={`stacked-cta__item-content ${activeCTA === item.id ? 'active' : ''}`}
        >
          <div className="container">
            <Text tag="h3" field={item.fields.SubTitle} />
            <Text tag="h2" field={item.fields.Title} />
            <RichText field={item.fields.Description} />
          </div>
        </div>
      ))}
    </>
  );

  const renderMobile = (
    <>
      <Accordion onChange={(e) => handleActiveAccordion(e)} preExpanded={[activeCTA]}>
        {stackedData.map((item) => (
          <AccordionItem key={item.id} uuid={item.id}>
            <video autoPlay={activeCTA === item.id} loop muted preload="auto" playsInline>
              <source src={item.fields.Video.value.href} type="video/mp4" />
            </video>
            <AccordionItemHeading id={item.id}>
              <AccordionItemButton>
                <span>{item.fields.SubTitle.value}</span>
                <span className="accordion__button--collapse">{plusIcon}</span>
                <span className="accordion__button--expand">{minusIcon}</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <Text tag="h2" field={item.fields.Title} />
              <RichText field={item.fields.Description} />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );

  return (
    <section className="stacked-cta" ref={sectionWrapper}>
      <>
        {isDesktop && renderDesktop}
        {!isDesktop && renderMobile}
      </>
    </section>
  );
};

export default StackedCTA;

const minusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_1618_3018"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1618_3018)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
  </svg>
);

const plusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_517_10254)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
    <mask
      id="mask1_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M14.25 14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75V8.25H14.25V14.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_517_10254)">
      <path d="M14.25 14.25V15.75V21.75H15.75V15.75V14.25V8.25H14.25V14.25Z" fill="white" />
    </g>
  </svg>
);
