import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useHistory } from 'react-router-dom';
import { Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../temp/config';
import MainNavigation from '../MainNavigation';

import { useQuery } from 'react-query';
import SocialLinks from '../SocialLinks';
import HeaderSearch from '../HeaderSearch';
import { object, shape } from 'prop-types';

// For GoogleAnalytics
import useGaTracker from '../../hooks/useGaTracker';

import useMatchMedia from '../../hooks/useMatchMedia';

const propTypes = {
  sitecoreContext: object.isRequired,
  fields: shape({
    siteLogo: object.isRequired,
  }),
};

const Header = (props) => {
  const { sitecoreContext, fields, t } = props;
  const isArabic = sitecoreContext.language === 'ar';
  const connectedMode = sitecoreContext.route.databaseName.indexOf('available') === -1;
  const editorClass = sitecoreContext.pageEditing ? 'editor' : '';
  const [checkedLanguageToggle, setCheckedLanguageToggle] = useState(isArabic);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  let location = useLocation();
  const history = useHistory();

  // Handled rendered HTLM depending on screen size
  const isDesktop = useMatchMedia('(min-width: 1200px)');

  const fetchMenu = async () => {
    let result;
    let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${sitecoreContext.language}&$filter=(TemplateName eq 'App Route' or TemplateName eq 'News Page') and FieldValues/showInNavigation eq '1'&$orderby=Path&$expand=Children($expand=FieldValues), FieldValues`;

    if (url) {
      const request = await fetch(url);
      result = await request.json();
      result = result.value;

      result.forEach(
        (menu) =>
          (menu.Path = connectedMode
            ? menu.Path.replace('/sitecore/content/ksp/home', `/${sitecoreContext.language}`)
            : menu.Path.replace('/sitecore/content/ksp/home', ''))
      );
    } else {
      result = [
        {
          Path: connectedMode ? `/${sitecoreContext.language}` : '/',
          FieldValues: { navigationTitle: 'Home' },
        },
        {
          Path: connectedMode ? `/${sitecoreContext.language}/news` : '/news',
          FieldValues: { navigationTitle: 'News' },
        },
        {
          Path: connectedMode ? `/${sitecoreContext.language}/contact` : '/contact',
          FieldValues: { navigationTitle: 'Contact' },
        },
      ];
    }

    return result;
  };

  useEffect(() => {
    if (isArabic) {
      setCheckedLanguageToggle(true);

      // Update lang attribute for <html> tag
      document.documentElement.setAttribute('lang', 'ar');
      document.documentElement.setAttribute('dir', 'rtl');
    }
  }, []);

  const menuItems = useQuery('mainMenu', fetchMenu, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  // Close Menu when change path
  useEffect(() => {
    handleMenuToggle(false);
  }, [location.pathname]);

  const handleMenuToggle = (isOpened) => {
    if (isOpened) {
      document.body.classList.add('has-overlay');
      setMenuIsOpen(true);
    } else {
      document.body.classList.remove('has-overlay');
      setMenuIsOpen(false);
    }
  };

  const handleLanguageSwitch = (toggled) => {
    setCheckedLanguageToggle(toggled);

    // Redirect after change language in connected mode
    if (connectedMode) {
      if (toggled) {
        history.push(`/ar${sitecoreContext.itemPath}`);
        history.go(0);
      } else {
        history.push(`/en${sitecoreContext.itemPath}`);
        history.go(0);
      }
    } else {
      // Update lang attribute for <html> tag
      document.documentElement.setAttribute('lang', toggled ? 'ar' : 'en');
      document.documentElement.setAttribute('dir', toggled ? 'rtl' : 'ltr');
    }
  };

  // For Google Analytics
  useGaTracker();

  useEffect(() => {
    const handleBackButton = () => {
      history.go(0);
    };
    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  return (
    <>
      <header className={editorClass}>
        <div className="container">
          <div
            className="header__wrapper"
            data-aos="fade-up"
            data-aos-once="true"
            data-aos-delay="400"
            data-aos-duration="1000"
          >
            <button className="header__menu-open" onClick={() => handleMenuToggle(!menuIsOpen)}>
              {menuOpenIcon}
            </button>

            <div className="header__language-toggler">
              <label className="switch">
                <input
                  type="checkbox"
                  name="language-toggle"
                  checked={checkedLanguageToggle}
                  onChange={() => handleLanguageSwitch(!checkedLanguageToggle)}
                />
                <span className="slider round"></span>
                <label className="switch-label">{checkedLanguageToggle ? 'AR' : 'EN'}</label>
              </label>
            </div>
            {isDesktop && (
              <div className="header__search-link">
                <Link to={`/${sitecoreContext.language}/search`}>{searchIcon}</Link>
              </div>
            )}

            <div className="header-logo">
              <Link to={`/${sitecoreContext.language}`}>
                <Image media={fields.siteLogo} alt="" />
              </Link>
            </div>
          </div>

          <div
            className={`header__menu-overlay ${menuIsOpen && 'is-open'}`}
            onClick={() => handleMenuToggle(!menuIsOpen)}
          ></div>
          <div className={`header__menu-container ${menuIsOpen && 'is-open'}`}>
            <button className="header__menu-close" onClick={() => handleMenuToggle(!menuIsOpen)}>
              {menuCloseIcon}
            </button>
            <MainNavigation fields={props.fields.navItems} />

            {!isDesktop && location.pathname !== `/${sitecoreContext.language}/search` && (
              <HeaderSearch />
            )}

            <div className="header__social-links">
              <SocialLinks isHeader={true} />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

Header.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(Header));

const menuOpenIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_b_1243_3215)">
      <circle cx="20" cy="20" r="20" fill="white" />
    </g>
    <rect x="15" y="15" width="15" height="2" fill="#78D64B" />
    <rect x="10" y="19" width="20" height="2" fill="#78D64B" />
    <rect x="10" y="23" width="13" height="2" fill="#78D64B" />
    <defs>
      <filter
        id="filter0_b_1243_3215"
        x="-20"
        y="-20"
        width="80"
        height="80"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1243_3215" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1243_3215"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

const menuCloseIcon = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.414062"
      y="23"
      width="32"
      height="2"
      transform="rotate(-45 0.414062 23)"
      fill="white"
    />
    <rect x="1.41406" width="32" height="2" transform="rotate(45 1.41406 0)" fill="white" />
  </svg>
);

const searchIcon = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 26.59L20.45 19C24.1993 14.4762 23.7315 7.80444 19.3875 3.84813C15.0435 -0.108179 8.35717 0.0478833 4.20249 4.20255C0.0478222 8.35723 -0.10824 15.0436 3.84807 19.3875C7.80438 23.7315 14.4761 24.1994 19 20.45L26.59 28L28 26.59ZM2.99998 12C2.99998 7.02948 7.02942 3.00004 12 3.00004C16.9705 3.00004 21 7.02948 21 12C21 16.9706 16.9705 21 12 21C7.02942 21 2.99998 16.9706 2.99998 12Z"
      fill="white"
    />
  </svg>
);
