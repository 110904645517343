import React from 'react';
import {
  Text,
  Image,
  DateField,
  Link,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const EventHero = (props) => {
  const { fields, sitecoreContext, t } = props;

  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }
  return (
    <div className="inner-page__top">
      <div className="inner-page__top--photo">
        <Image field={fields.heroImage} />
      </div>
      <div className="container">
        <div className="inner-page__top--content">
          <div className="events__box">
            <Image className="content-hero__desc--icon" field={fields.heroIcon} />
            <Text tag="h1" className="events__title" field={fields.title} />
            <div className="events__row">
              <span className="events__date">
                <DateField
                  field={fields.dateFrom}
                  render={(date) => moment(date).format('MMMM DD, YYYY')}
                />
                {'  -  '}
                <DateField
                  field={fields.dateTo}
                  render={(date) => moment(date).format('MMMM DD, YYYY')}
                />
              </span>

              <span className="events__location">
                {fields.location && fields.location.value
                  ? fields.location.value.split('\n')[0]
                  : ''}
              </span>
            </div>
            <div className="events__desc">
              <Text field={fields.shortdescription} />
            </div>
            <div className="events__actions">
              <Link className="events__actions--btnBook btn btn-secondary" field={fields.bookLink}>
                {t('BookEventLabel')}
              </Link>

              <Link
                className="events__actions--btncontact btn btn-primary"
                field={fields.contactLink}
              >
                {t('ContactUsLabel')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(EventHero));
