import React, { useEffect, useMemo, useState } from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { Link, useLocation } from 'react-router-dom';
import NewsItem from '../NewsItem';
import { object, func } from 'prop-types';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Pagination } from 'swiper';
// Import Swiper styles
import '../../assets/sass/components/_swiper.scss';

import Pager from '../Pager';
import Spinner from '../Spinner';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

const Search = ({ sitecoreContext, t }) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchNewsResults, setSearchNewsResults] = useState([]);
  const [searchEventsResults, setSearchEventsResults] = useState([]);
  const [searchPagesResults, setSearchPagesResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState('all');
  const [searchQuery, setSearchQuery] = useState(
    "TemplateName eq 'News Article' or TemplateName eq 'Event' or TemplateName eq 'Inner Page'" // eslint-disable-line quotes
  );
  const [isLoading, setIsLoading] = useState(false);

  /* Pagination */
  const itemsPerPage = 6;
  const [currentEventPage, setCurrentEventPage] = useState(1);
  const [currentContentPage, setCurrentContentPage] = useState(1);

  const currentEventsData = useMemo(() => {
    const firstPageIndex = (currentEventPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return searchEventsResults.slice(firstPageIndex, lastPageIndex);
  }, [currentEventPage, searchEventsResults]);

  const currentContentData = useMemo(() => {
    const firstPageIndex = (currentContentPage - 1) * itemsPerPage;
    const lastPageIndex = firstPageIndex + itemsPerPage;
    return searchPagesResults.slice(firstPageIndex, lastPageIndex);
  }, [currentContentPage, searchPagesResults]);

  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value.toLowerCase());

    // Reset current event +content page for pager
    setCurrentEventPage(1);
    setCurrentContentPage(1);
  };

  // If there is search query url, update search input to trigger search
  const search = useLocation().search;
  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams.get('q') !== null) {
      setSearchInput(searchParams.get('q').toLowerCase());
    }
  }, []);

  useEffect(() => {
    if (searchInput.length > 2) {
      fetchData(searchInput);
    }
  }, [searchInput, searchQuery, filteredResults]);

  const handleFilters = (filter) => {
    setFilteredResults(filter);

    switch (filter) {
      case 'news':
        setSearchQuery("TemplateName eq 'News Article'"); // eslint-disable-line quotes
        break;

      case 'events':
        setSearchQuery("TemplateName eq 'Event'"); // eslint-disable-line quotes
        break;

      case 'pages':
        setSearchQuery("TemplateName eq 'Inner Page'"); // eslint-disable-line quotes
        break;

      default:
        setSearchQuery(
          "TemplateName eq 'News Article' or TemplateName eq 'Event' or TemplateName eq 'Inner Page'" // eslint-disable-line quotes
        );
    }

    // Reset current event +content page for pager
    setCurrentEventPage(1);
    setCurrentContentPage(1);
  };

  const fetchData = async (query) => {
    setIsLoading(true);
    await fetch(
      `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${sitecoreContext.language}&$filter=(${searchQuery}) and (contains(FieldValues/NewsContent,'${query}') or contains(FieldValues/description,'${query}') or contains(FieldValues/NewsContent,'${query}') or contains(FieldValues/body,'${query}') or contains(FieldValues/content,'${query}') or contains(FieldValues/pagetitle,'${query}'))&$expand=FieldValues&$top=100`
    )
      .then((response) => response.json())
      .then((json) => {
        setSearchNewsResults(() =>
          json.value.filter((item) => item.TemplateName === 'News Article')
        );

        setSearchEventsResults(() => json.value.filter((item) => item.TemplateName === 'Event'));
        setSearchPagesResults(() =>
          json.value.filter((item) => item.TemplateName === 'Inner Page')
        );
        setIsLoading(false);
      });
  };

  return (
    <section className="container-bleed container-bleed__has-curved-bg white-curved">
      <div className="container">
        <div className="search__input-wrapper">
          <div className="search__input-field">
            {searchIcon}
            <input placeholder={t('Search')} value={searchInput} onChange={handleChange} />
          </div>
        </div>

        {isLoading && <Spinner spinColor="#bc204b" />}

        {searchInput.length > 2 && !isLoading && (
          <>
            <div className="search__header">
              <h2>{t('searchResults')}</h2>
              <div className="search__header-filters">
                <button
                  className={`btn btn-rounded ${filteredResults === 'all' && 'btn-selected'}`}
                  onClick={() => handleFilters('all')}
                >
                  {t('allResults')}
                </button>
                <button
                  className={`btn btn-rounded ${filteredResults === 'news' && 'btn-selected'}`}
                  onClick={() => handleFilters('news')}
                >
                  {t('News')}
                </button>
                <button
                  className={`btn btn-rounded ${filteredResults === 'events' && 'btn-selected'}`}
                  onClick={() => handleFilters('events')}
                >
                  {t('Events')}
                </button>
                <button
                  className={`btn btn-rounded ${filteredResults === 'pages' && 'btn-selected'}`}
                  onClick={() => handleFilters('pages')}
                >
                  {t('Pages')}
                </button>
              </div>
              <hr />
            </div>

            {/* News search results */}
            <div
              className={`search__results-wrapper ${
                filteredResults === 'all' || filteredResults === 'news' ? '' : 'hidden'
              }`}
            >
              <h2 className="search__results-title">{t('News')}</h2>
              {searchNewsResults.length > 0 ? (
                <Swiper
                  style={{ '--swiper-pagination-bullet-horizontal-gap': '10px' }}
                  className="latest-news__wrapper"
                  key={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
                  dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
                  spaceBetween={20}
                  modules={[Pagination]}
                  pagination={{
                    clickable: 'true',
                    type: 'bullets',
                    renderBullet: function (index, className) {
                      return `<span class="${className}"><i></i><b></b></span>`;
                    },
                  }}
                  freeMode={true}
                  slidesPerView={1.15}
                  centeredSlides={true}
                  slidesPerGroup={1}
                  threshold={'150px'}
                  initialSlide={0}
                  breakpoints={{
                    768: {
                      freeMode: false,
                      slidesPerView: 2,
                      centeredSlides: false,
                      slidesPerGroup: 2,
                    },
                    1024: {
                      freeMode: false,
                      slidesPerView: 3,
                      centeredSlides: false,
                      slidesPerGroup: 3,
                    },
                  }}
                >
                  {searchNewsResults.map((newsItem) => (
                    <SwiperSlide key={newsItem.Id}>
                      <NewsItem data={newsItem.FieldValues} key={newsItem.Id} url={newsItem.Path} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <div className="search__no-results">
                  {t('NoResults')} {searchInput}
                </div>
              )}
            </div>

            {/* Events search results */}
            <div
              className={`search__results-wrapper ${
                filteredResults === 'all' || filteredResults === 'events' ? '' : 'hidden'
              }`}
            >
              <h2 className="search__results-title">{t('Events')}</h2>

              {searchEventsResults.length > 0 ? (
                <>
                  {currentEventsData.map((item) => (
                    <div className="search__result-item" key={item.Id}>
                      <h3 className="search__result-item-title">
                        <Link
                          to={item.Path.replace(
                            '/sitecore/content/ksp/home',
                            `/${sitecoreContext.language}`
                          ).toLowerCase()}
                        >
                          {item.FieldValues.title}
                        </Link>
                      </h3>
                      <HTMLEllipsis
                        unsafeHTML={item.FieldValues.description}
                        maxLine="4"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </div>
                  ))}

                  <Pager
                    currentPage={currentEventPage}
                    totalCount={searchEventsResults.length}
                    pageSize={itemsPerPage}
                    onPageChange={(page) => setCurrentEventPage(page)}
                  />
                </>
              ) : (
                <div className="search__no-results">
                  {t('NoResults')} {searchInput}
                </div>
              )}
            </div>

            {/* Pages search results */}
            <div
              className={`search__results-wrapper ${
                filteredResults === 'all' || filteredResults === 'pages' ? '' : 'hidden'
              }`}
            >
              <h2 className="search__results-title">{t('Pages')}</h2>

              {searchPagesResults.length > 0 ? (
                <>
                  {currentContentData.map((item) => (
                    <div className="search__result-item" key={item.Id}>
                      <h3 className="search__result-item-title">
                        <Link
                          to={item.Path.replace(
                            '/sitecore/content/ksp/home',
                            `/${sitecoreContext.language}`
                          ).toLowerCase()}
                        >
                          {item.FieldValues.title}
                        </Link>
                      </h3>
                      <HTMLEllipsis
                        unsafeHTML={
                          item.FieldValues.heroDescription !== ''
                            ? item.FieldValues.heroDescription
                            : item.FieldValues.body
                        }
                        maxLine="4"
                        ellipsis="..."
                        basedOn="letters"
                      />
                    </div>
                  ))}

                  <Pager
                    currentPage={currentContentPage}
                    totalCount={searchPagesResults.length}
                    pageSize={itemsPerPage}
                    onPageChange={(page) => setCurrentContentPage(page)}
                  />
                </>
              ) : (
                <div className="search__no-results">
                  {t('NoResults')} {searchInput}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

Search.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(Search));

const searchIcon = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 26.59L20.45 19C24.1993 14.4762 23.7315 7.80444 19.3875 3.84813C15.0435 -0.108179 8.35717 0.0478833 4.20249 4.20255C0.0478222 8.35723 -0.10824 15.0436 3.84807 19.3875C7.80438 23.7315 14.4761 24.1994 19 20.45L26.59 28L28 26.59ZM2.99998 12C2.99998 7.02948 7.02942 3.00004 12 3.00004C16.9705 3.00004 21 7.02948 21 12C21 16.9706 16.9705 21 12 21C7.02942 21 2.99998 16.9706 2.99998 12Z"
      fill="black"
    />
  </svg>
);
