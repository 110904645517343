import React from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { object } from 'prop-types';

const propTypes = {
  rendering: object.isRequired,
};

const ContainerWithPattern = ({ rendering }) => (
  <>
    <div className="container-bleed container-bleed__has-curved-bg">
      <div className="container">
        <div className="inner-page__content">
          <Placeholder name="container" rendering={rendering} />
        </div>
        <Placeholder name="container-bottom" rendering={rendering} />
      </div>
    </div>
  </>
);
ContainerWithPattern.propTypes = propTypes;
export default ContainerWithPattern;
