import React, { useState } from 'react';
import { Image, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import ModalVideo from 'react-modal-video';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import config from '../../temp/config';
import { object, func } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
  rendering: object.isRequired,
};

const Features = (props) => {
  const { sitecoreContext, t, rendering } = props;
  const [isOpen, setOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [currentVideo, setCurrentVideo] = useState('');
  // Toggle overlay class on body
  if (typeof document !== 'undefined') {
    if (isOpen) {
      document.body.classList.add('has-overlay');
    } else {
      document.body.classList.remove('has-overlay');
    }
  }

  const playVideo = (youtubeId) => {
    // Check if it's a playlist or a single video
    const isPlaylist = youtubeId.startsWith('PL');
    const videoId = isPlaylist ? `playlist?list=${youtubeId}` : youtubeId;

    setCurrentVideo(videoId);
    setOpen(true);
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const items = rendering.fields.items;

  return (
    <>
      <section className="features">
        <div
          className="features__wrapper"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="750"
          data-aos-duration="500"
        >
          {items?.slice(0, 3).map((item) => (
            <div className="features__row--container" key={item.Id}>
              {!isHovering && (
                <div
                  className="container-nature"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <div className="features__row--img-overlay">
                    <Image className="image" media={item.fields.Image} />
                  </div>
                  <Text className="where" tag="span" field={item.fields.Title} />
                </div>
              )}

              {isHovering && (
                <div className="feature-container">
                  <div className="features__row--img-overlay">
                    <Image className="image" media={item.fields.Image} />
                  </div>
                  <Text className="where" tag="span" field={item.fields.Title} />
                  <div className={`overlay  overlay__${item.fields.Color.value}`}>
                    <div className="text">
                      {/* <Text tag="div" field={item.fields.Title} /> */}
                      {item.fields.SubTitle.value && <Text tag="p" field={item.fields.SubTitle} />}
                      {item.fields.YoutubeID.value ? (
                        <button onClick={() => playVideo(item.fields.YoutubeID.value)}>
                          <div>{t('WatchNow')}</div>
                          <i>{playIcon}</i>
                        </button>
                      ) : (
                        item.fields.Link?.value &&
                        item.fields.LinkTitle?.value && (
                          <Link
                            className="events__actions--btnBook btn btn-secondary"
                            field={item.fields.Link}
                            target="_blank"
                          >
                            {item.fields.LinkTitle.value}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div
          className="features__wrapper"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="1000"
          data-aos-duration="500"
        >
          {items?.slice(3, items.data?.length).map((item) => (
            <div className="features__row--container" key={item.Id}>
              {!isHovering && (
                <div
                  className="container-nature"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  <div className="features__row--img-overlay">
                    <Image className="image" media={item.fields.Image} />
                  </div>
                  <Text className="where" tag="span" field={item.fields.Title} />
                </div>
              )}

              {isHovering && (
                <div className="feature-container">
                  <div className="features__row--img-overlay">
                    <Image className="image" media={item.fields.Image} />
                  </div>
                  <Text className="where" tag="span" field={item.fields.Title} />
                  <div className={`overlay  overlay__${item.fields.Color.value}`}>
                    <div className="text">
                      {/* <Text tag="div" field={item.fields.Title} /> */}
                      {item.fields.SubTitle.value && <Text tag="p" field={item.fields.SubTitle} />}
                      {item.fields.YoutubeID.value && (
                        <button onClick={() => playVideo(item.fields.YoutubeID.value)}>
                          <div>{t('WatchNow')}</div>
                          <i>{playIcon}</i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </section>

      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={currentVideo}
        onClose={() => setOpen(false)}
        config={{
          url: `https://www.youtube.com/embed/${currentVideo}`,
          iframe: true, // Ensures custom iframe embed works for playlists
        }}
      />
    </>
  );
};

Features.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(Features));

const playIcon = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="rgba(0,0,0,0)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16.5" cy="16.5" r="16" stroke="white" />
    <path
      d="M23.5 15.634C24.1667 16.0189 24.1667 16.9811 23.5 17.366L13.75 22.9952C13.0833 23.3801 12.25 22.899 12.25 22.1292V10.8708C12.25 10.101 13.0833 9.61991 13.75 10.0048L23.5 15.634Z"
      fill="white"
    />
  </svg>
);
