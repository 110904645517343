import React, { useEffect, useRef, useState } from 'react';
import { Text, Image as SitecoreImage } from '@sitecore-jss/sitecore-jss-react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import download from '../../imgs/download.png';

const Greetings = ({ sitecoreContext, rendering, t }) => {
  const images = rendering.fields.items;
  const firstImage = images[0].fields.Image.value.src;
  const [text, setText] = useState('');
  const [image, setImage] = useState(firstImage);
  const [downloadLink, setDownload] = useState('');
  const canvas = useRef(null);

  if (typeof CanvasRenderingContext2D !== 'undefined') {
    CanvasRenderingContext2D.prototype.wrapText = function (text, x, y, maxWidth, lineHeight) {
      var lines = text.split('\n');

      for (var i = 0; i < lines.length; i++) {
        var words = lines[i].split(' ');
        var line = '';

        for (var n = 0; n < words.length; n++) {
          var testLine = line + words[n] + ' ';
          var metrics = this.measureText(testLine);
          var testWidth = metrics.width;
          if (testWidth > maxWidth && n > 0) {
            this.fillText(line, x, y);
            line = words[n] + ' ';
            y += lineHeight;
          } else {
            line = testLine;
          }
        }

        this.fillText(line, x, y);
        y += lineHeight;
      }
    };
  }
  useEffect(() => {
    const ctx = canvas.current.getContext('2d');
    let img = new Image();
    img.src = image;
    img.onload = function () {
      ctx.drawImage(img, 0, 0, 850, 650);
      ctx.font = '48px KSP-Medium';
      ctx.fillStyle = '#B8E0DF';
      ctx.textAlign = 'center';
      ctx.wrapText(text, 425, 560, 500, 40);
      setDownload(canvas.current.toDataURL());
    };
  });

  return (
    <>
      <div className="inner-page__top">
        <div className="inner-page__top--photo">
          <SitecoreImage media={sitecoreContext.route.fields.heroImage} />
        </div>
        <div className="container">
          <div className="inner-page__top--content">
            <Text tag="h1" field={sitecoreContext.route.fields.title} />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="article__box greeting-padding-container">
          <div className="container">
            <div className="home">
              <div className="container-greetings">
                <div className="sidebar-greetings">
                  <h4>{t('ChooseAnImage')}</h4>

                  <div className="choose-image-scroll">
                    <div className="imgs">
                      {images.map((ig, index) => (
                        <SitecoreImage
                          width="auto"
                          height="auto"
                          media={ig.fields.Image}
                          key={index}
                          onClick={() => setImage(ig.fields.Image.value.src)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="main-greetings">
                  <canvas className="canvas-width" ref={canvas} width={850} height={650} />
                  <textarea
                    className="text-language"
                    value={text}
                    placeholder={t('ReplaceThisText')}
                    onChange={(event) => setText(event.target.value)}
                  />
                  <a
                    href={downloadLink}
                    download="KSPF Greeting Card.png"
                    className="link"
                    draggable="false"
                    data-tooltip="Click To Download"
                  >
                    <img src={download} className="downloadIcon" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withSitecoreContext()(Greetings));
