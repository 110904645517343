import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HeaderSearch = ({ sitecoreContext, t }) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <div className="header__search">
      <div className="header__search-input">
        {searchIcon}
        <input
          placeholder={t('Search')}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value.toLowerCase())}
        />
        <Link
          className={`header__search-btn ${searchInput.length < 3 ? 'disabled' : ''}`}
          to={`/${sitecoreContext.language}/search?q=${searchInput}`}
        >
          {searchArrowIcon}
        </Link>
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(HeaderSearch));

const searchIcon = (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28 26.59L20.45 19C24.1993 14.4762 23.7315 7.80444 19.3875 3.84813C15.0435 -0.108179 8.35717 0.0478833 4.20249 4.20255C0.0478222 8.35723 -0.10824 15.0436 3.84807 19.3875C7.80438 23.7315 14.4761 24.1994 19 20.45L26.59 28L28 26.59ZM2.99998 12C2.99998 7.02948 7.02942 3.00004 12 3.00004C16.9705 3.00004 21 7.02948 21 12C21 16.9706 16.9705 21 12 21C7.02942 21 2.99998 16.9706 2.99998 12Z"
      fill="white"
    />
  </svg>
);

const searchArrowIcon = (
  <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_303)">
      <mask
        id="mask0_1_303"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="20"
      >
        <path d="M15 0L13.59 1.41L21.17 9H0V11H21.17L13.59 18.59L15 20L25 10L15 0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_1_303)">
        <path d="M15 0L13.59 1.41L21.17 9H0V11H21.17L13.59 18.59L15 20L25 10L15 0Z" fill="white" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1_303">
        <rect width="25" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
