import React, { useEffect } from 'react';
import { Image, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import { object } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
};

const HeroImage = (props) => {
  const { sitecoreContext, fields } = props;

  // This to add class to body that control some styles for the component
  let location = useLocation();

  useEffect(() => {
    location = location.pathname.split('/');
    let bodyClass = location[location.length - 1];
    if (bodyClass === '' || bodyClass === 'en' || bodyClass === 'ar') {
      bodyClass = 'homepage';
    } else {
      bodyClass = bodyClass.toLowerCase().replaceAll(' ', '-');
    }
    document.body.classList.add(bodyClass);

    return () => {
      document.body.classList.remove(bodyClass);
    };
  }, []);

  return (
    <div className="inner-page__top">
      <div className="inner-page__top--photo">
        <Image media={fields.heroImage} />
      </div>
      <div className="container">
        <div className="inner-page__top--content">
          {fields.showIcon.value ? (
            <Image
              className="content__img--hero"
              field={fields.heroIcon}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1250"
              data-aos-duration="1000"
            />
          ) : (
            ''
          )}
          <Text tag="h1" field={fields.title} />
          {fields.showDescription.value ? <Text tag="p" field={fields.heroDescription} /> : ''}

          {/* sitecoreContext.route.fields.body && (
            <div className="inner-page__top--content-text">
              <RichText field={sitecoreContext.route.fields.body} />
            </div>
          )*/}
        </div>
      </div>
    </div>
  );
};

HeroImage.propTypes = propTypes;
export default withSitecoreContext()(HeroImage);
