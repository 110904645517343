import React from 'react';
import { Text, Image, DateField, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const FeaturedEvent = (props) => {
  const { fields, sitecoreContext, t } = props;
  if (!fields.FeaturedEvent) return <div></div>;
  if (sitecoreContext.language === 'ar') {
    // Update Months in Arabic
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }

  return (
    <div className="inner-page__top">
      <div className="inner-page__top--photo">
        <Image field={fields.FeaturedEvent.fields.heroImage} />
      </div>
      <div className="container">
        <div className="inner-page__top--content">
          <div className="events__box">
            <Image
              className="content-hero__desc--icon"
              field={fields.FeaturedEvent.fields.heroIcon}
            />
            <Text tag="h1" className="events__title" field={fields.FeaturedEvent.fields.title} />
            <div className="events__row">
              <span className="events__date">
                <DateField
                  field={fields.FeaturedEvent.fields.dateFrom}
                  render={(date) => moment(date).format('MMMM DD, YYYY')}
                />
                {'  -  '}
                <DateField
                  field={fields.FeaturedEvent.fields.dateTo}
                  render={(date) => moment(date).format('MMMM DD, YYYY')}
                />
              </span>
              <span className="events__location">
                {fields.FeaturedEvent.fields.location && fields.FeaturedEvent.fields.location.value
                  ? fields.FeaturedEvent.fields.location.value.split('\n')[0]
                  : ''}
              </span>
            </div>
            <div className="events__desc">
              <Text field={fields.FeaturedEvent.fields.shortdescription} />
            </div>
            <div className="events__actions">
              <Link
                className="events__actions--btnBook btn btn-secondary"
                to={`/${sitecoreContext.language}${fields.FeaturedEvent.url}`}
              >
                {t('EventDetails')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(FeaturedEvent));
