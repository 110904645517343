import React from 'react';
import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import { withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../temp/config';
import CustomFieldFactory from './CustomFieldFactory.js';

const SitecoreForm = ({ fields, history }) => {
  return (
    <div className="apply-now-container" id="apply-now-container">
      <div className="container">
        <Form
          form={fields}
          sitecoreApiHost={sitecoreApiHost}
          sitecoreApiKey={sitecoreApiKey}
          onRedirect={(url) => history.push(url)}
          fieldFactory={CustomFieldFactory}
        />
      </div>
    </div>
  );
};

export default withRouter(SitecoreForm);
