import React, { useState, useEffect } from 'react';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import CloseBanner from '../../assets/img/close-icon.svg';

const NotificationsBanner = ({ fields }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true); // Add a loading state

  const toggleContainerVisibility = () => {
    setIsVisible(!isVisible);
    // Store in local storage that the banner has been closed
    localStorage.setItem('isBannerClosed', 'true');
  };

  useEffect(() => {
    // Check if the banner was previously closed and stored in local storage
    const isBannerClosed = localStorage.getItem('isBannerClosed');

    if (isBannerClosed === 'true') {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
    setLoading(false); // Set loading to false when the check is complete
  }, []);

  return (
    <>
      {isVisible && (
        <div className="notification-container--layout">
          <div className="notification-container">
            <span>
              <Text field={fields.Text} />
            </span>
            <div className="notification-container--actions">
              <Link className="btn btn-notification-primary btn-rounded" field={fields.Link1} />
              <Link className="btn btn-notification-secondary btn-rounded" field={fields.Link2} />
            </div>
          </div>
          {loading
            ? null
            : isVisible && (
                <a className="notification-container--close" onClick={toggleContainerVisibility}>
                  <img src={CloseBanner} />
                </a>
              )}
        </div>
      )}
    </>
  );
};

export default NotificationsBanner;
