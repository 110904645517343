import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const MainNavigation = ({ fields, sitecoreContext }) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(null);

  const toggleSubMenu = (Id) => {
    if (isSubMenuOpen === Id) {
      setIsSubMenuOpen(null);
    } else {
      setIsSubMenuOpen(Id);
    }
  };

  function calculateMenuClasses(menu, isSubMenuOpen) {
    const baseClass = 'menu-item';
    const arrowCollapseClass = 'menu-item__arrow-collapse';
    const arrowCollapseCloseClass = 'menu-item__arrow-collapse-close';

    const classes = [baseClass];

    classes.push(arrowCollapseClass);

    if (isSubMenuOpen === menu.id) {
      classes.push(arrowCollapseCloseClass);
    }

    return classes.join(' ');
  }

  return (
    <>
      <div className="desktop-view-menu">
        <ul className="header__menu-links">
          {fields.items.map((item) => (
            <li
              key={item.id}
              className={item.children ? 'menu-item menu-item__arrow-right' : 'menu-item'}
            >
              {item.showLink ? (
                <>
                  {item.url === `/${sitecoreContext.language}/` ? (
                    <NavLink exact activeClassName="active" to={item.url} className="arrow-menu">
                      {item.title}
                    </NavLink>
                  ) : (
                    <NavLink activeClassName="active" to={item.url} className="arrow-menu">
                      {item.title}
                    </NavLink>
                  )}
                </>
              ) : (
                <span className="no-link arrow-menu">{item.title}</span>
              )}

              {item.children ? (
                <ul className="sub-menu">
                  <div className="sub-menu__sub-bg"></div>
                  {item.children.map((child) => (
                    <li key={child.id}>
                      {child.url === `/${sitecoreContext.language}${sitecoreContext.itemPath}` ? (
                        <NavLink
                          exact
                          className="sub-menu__text-sub"
                          activeClassName="active"
                          to={child.url}
                        >
                          {item.title}
                        </NavLink>
                      ) : (
                        <NavLink
                          className="sub-menu__text-sub"
                          activeClassName="active"
                          to={child.url}
                        >
                          {child.title}
                        </NavLink>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </li>
          ))}
        </ul>
      </div>
      {/* Mobile version*/}
      <div className="mobile-view-menu">
        <ul className="header__menu-links">
          {fields.items.map((item) => (
            <li
              key={item.id}
              className={calculateMenuClasses(item, isSubMenuOpen)}
              onClick={() => toggleSubMenu(item.id)}
            >
              {item.children ? (
                <button
                  activeClassName="active"
                  className="collapse-mobile mobile-view-menu__btn-collapse"
                >
                  {item.title}
                </button>
              ) : item.url === `/${sitecoreContext.language}/` ? (
                <NavLink exact activeClassName="active" to={item.url} className="arrow-menu">
                  {item.title}
                </NavLink>
              ) : (
                <NavLink activeClassName="active" to={item.url} className="arrow-menu">
                  {item.title}
                </NavLink>
              )}
              {item.children ? (
                <div className={`sub-menu-mobile ${item.id === isSubMenuOpen ? 'open' : ''}`}>
                  <ul className="sub-menu-mobile__list-container">
                    {item.children.map((child) => (
                      <li key={child.id}>
                        {child.url === `/${sitecoreContext.language}${sitecoreContext.itemPath}` ? (
                          <NavLink
                            exact
                            activeClassName="active"
                            to={child.url}
                            className="arrow-menu"
                          >
                            {item.title}
                          </NavLink>
                        ) : (
                          <NavLink activeClassName="active" to={child.url} className="arrow-menu">
                            {child.title}
                          </NavLink>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                ''
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
export default withSitecoreContext()(MainNavigation);
