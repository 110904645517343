import React from 'react';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { EffectCards, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import '../../../node_modules/swiper/swiper.scss';

import 'swiper/modules/effect-cards/effect-cards.scss';

const StackedCarousel = ({ fields, sitecoreContext }) => {
  return (
    <>
      <Swiper
        modules={[EffectCards, Pagination]}
        effect={'cards'}
        grabCursor={true}
        pagination={{ clickable: true }} // Enable clickable pagination dots
        className="mySwiper"
        dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
      >
        {fields.items.map(({ id, fields }) => (
          <SwiperSlide key={id}>
            <Image field={fields.image} />
            <Text tag="h3" field={fields.title} />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
export default withSitecoreContext()(StackedCarousel);
