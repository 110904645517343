import React from 'react';
import { Link, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
// import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { object } from 'prop-types';

const propTypes = {
  fields: object.isRequired,
};

const FAQs = ({ fields }) => {
  return (
    <>
      <div className="faq__wrapper">
        <Text tag="h2" className="faq__title" field={fields.Title} />
        <Accordion allowZeroExpanded>
          {fields.items.map((faq, index) => (
            <AccordionItem key={index}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <Text tag="span" field={faq.fields.Question} />
                  <span className="accordion__button-arrow">{accordionArrow}</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <RichText field={faq.fields.Answer} />
                {faq.fields.Link.value.text && (
                  <Link className="btn btn-secondary btn-rounded" field={faq.fields.Link} />
                )}
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </>
  );
};

const accordionArrow = (
  <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 12L0 2L1.414 0.586L10 9.172L18.586 0.586L20 2L10 12Z" fill="#414855" />
  </svg>
);

FAQs.propTypes = propTypes;

export default withSitecoreContext()(FAQs);
