import React from 'react';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { object, shape, array } from 'prop-types';

const propTypes = {
  fields: shape({
    Icon: object.isRequired,
    LeftText: object.isRequired,
    RightText: object.isRequired,
    Video: object.isRequired,
    items: array,
    ShowChildren: object.isRequired,
    UseImage: object.isRequired,
    BackgroundImage: object.isRequired,
  }),
};

const TwoColumnsCTA = ({ fields }) => {
  const {
    Icon,
    Title,
    LeftText,
    RightText,
    Video,
    items,
    ShowChildren,
    UseImage,
    BackgroundImage,
  } = fields;

  return (
    <section className="twocolumns-cta">
      {UseImage.value || Video.value.href === '' ? (
        <div className="heroimage__image">
          <img src={BackgroundImage.value.src} alt={Title.value} />
        </div>
      ) : (
        <video loop autoPlay muted preload="auto" playsInline>
          <source src={Video.value.href} type="video/mp4" />
        </video>
      )}

      <div className="container">
        <div className="twocolumns-cta__header">
          <Image className="twocolumns-cta__header-icon" media={Icon} />
          <Text tag="h2" field={Title} />
        </div>
        <hr />
        <div className="twocolumns-cta__body">
          <RichText field={LeftText} />
          <RichText field={RightText} />
        </div>
        <ul className="twocolumns-cta__brands">
          {items &&
            ShowChildren.value &&
            items.map((brand) => (
              <li key={brand.id}>
                <Image media={brand.fields.Image} />
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
};

TwoColumnsCTA.propTypes = propTypes;

export default TwoColumnsCTA;
