import React from 'react';
import {
  RichText,
  DateField,
  Text,
  withSitecoreContext,
  Link,
} from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const EventDetails = (props) => {
  const { rendering, sitecoreContext, t } = props;
  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }

  return (
    <div className="container">
      <div className="Event-details">
        <div className="Event-details__desc">
          <RichText field={rendering.fields.content} />
        </div>
        <div className="Event-details__boxInfo">
          <h4 className="Event-details__boxInfo--title">{t('DateLabel')}</h4>
          <p className="Event-details__boxInfo--text">
            <DateField
              field={rendering.fields.dateFrom}
              render={(date) => moment(date).format('MMMM DD YYYY HH:mm')}
            />{' '}
            -
            <br />
            <DateField
              field={rendering.fields.dateTo}
              render={(date) => moment(date).format('MMMM DD YYYY HH:mm')}
            />
          </p>

          <h4 className="Event-details__boxInfo--title">{t('LocationLabel')}</h4>
          <p className="Event-details__boxInfo--text">
            <Text field={rendering.fields.location} />
          </p>

          <div className="Event-details__events__actions">
            <Link
              className="events__actions--btnBook btn btn-secondary"
              field={rendering.fields.bookLink}
            >
              {t('BookEventLabel')}
            </Link>

            <Link
              className="events__actions--btncontact btn btn-primary"
              field={rendering.fields.contactLink}
            >
              {t('ContactUsLabel')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withSitecoreContext()(EventDetails));
