import React, { useEffect, useRef } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { Link as PageLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { object, shape } from 'prop-types';
import useMatchMedia from '../../hooks/useMatchMedia';

const propTypes = {
  fields: shape({
    Details: object.isRequired,
    FlipAlignment: object.isRequired,
    Image: object.isRequired,
    Link: object.isRequired,
    Title: object.isRequired,
  }),
};

const FullCTA = ({ fields, t }) => {
  const { Details, FlipAlignment, Image, Link, Title } = fields;

  const fullCTAClass = FlipAlignment.value ? 'full-cta flipped' : 'full-cta';

  // Handled rendered HTLM depending on screen size
  const isDesktop = useMatchMedia('(min-width: 1200px)');

  const sectionWrapper = useRef(null);
  const sectionCursor = useRef(null);

  useEffect(() => {
    if (Link.value.href !== '' && isDesktop) {
      const element = sectionWrapper.current;
      const handleCursor = (event) => {
        const { clientX, clientY } = event;

        const rect = sectionWrapper.current.getBoundingClientRect();
        const mouseX = clientX;
        const mouseY = clientY;

        sectionCursor.current.style.left = `${mouseX - rect.left - 90}px`;
        sectionCursor.current.style.top = `${mouseY - rect.top - 90}px`;
      };

      element.addEventListener('mousemove', handleCursor);

      return () => {
        element.removeEventListener('mousemove', handleCursor);
      };
    }
  }, [Link, isDesktop]);

  const renderDesktop = (
    <section className={fullCTAClass} ref={sectionWrapper}>
      <div className="full-cta__image-photo">
        <img src={Image.value.src} alt={Image.value.value} />
      </div>
      <div className="container">
        <div className="full-cta__content">
          <Text tag="h2" field={Title} />
          <RichText field={Details} />
        </div>
      </div>
      {Link.value.href !== '' && (
        <PageLink to={Link.value.href} className="full-cta__cursor" ref={sectionCursor}>
          {t('learnMore')}
        </PageLink>
      )}
    </section>
  );

  const renderMobile = (
    <section className={fullCTAClass}>
      <div className="full-cta__image-photo">
        <img src={Image.value.src} alt={Image.value.value} />
      </div>
      <div className="container">
        <div className="full-cta__content">
          <Text tag="h2" field={Title} />
          <RichText field={Details} />
          {Link.value.href !== '' && (
            <div className="full-cta__btn">
              <PageLink to={Link.value.href} className="btn btn-primary btn-rounded">
                {t('learnMore')}
              </PageLink>
            </div>
          )}
        </div>
      </div>
    </section>
  );

  return <>{isDesktop ? renderDesktop : renderMobile}</>;
};

FullCTA.propTypes = propTypes;

export default withTranslation()(FullCTA);
