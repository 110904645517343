import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination } from 'swiper';
// Import Swiper styles
import '../../assets/sass/components/_swiper.scss';
import { object, shape, array } from 'prop-types';
import useMatchMedia from '../../hooks/useMatchMedia';
import { Image, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

const propTypes = {
  fields: shape({
    playSpeed: object.isRequired,
    items: array.isRequired,
  }).isRequired,
};

const VerticalCarousel = ({ fields, sitecoreContext }) => {
  const { playSpeed, items } = fields;
  const sliderPlaySpeed = playSpeed.value ? playSpeed.value : 10;

  // matches bp-large-min in breakpoints.scss
  const isDesktop = useMatchMedia('(min-width: 1024px)');

  const [renderSwipers, setRenderSwipers] = useState(false);

  useEffect(() => {
    // required to let text load and prevent swiper init errors
    setRenderSwipers(true);
  }, []);

  const renderDesktop = (
    <>
      {renderSwipers && (
        <div className="vertical-carousel__wrapper">
          <Swiper
            direction="vertical"
            observer={true}
            observeParents={true}
            style={{ '--swiper-pagination-bullet-vertical-gap': '10px' }}
            className="container--bleed"
            key={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
            dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
            spaceBetween={0}
            modules={[Pagination, Autoplay]}
            pagination={{
              clickable: 'true',
              type: 'bullets',
              renderBullet: function (index, className) {
                return `<span class="${className}"><i></i><b style="--animation-duration: ${sliderPlaySpeed}s"></b></span>`;
              },
            }}
            autoplay={{
              delay: sliderPlaySpeed * 1000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true,
            }}
            loop={items.length > 1}
            slidesPerView={1}
            slidesPerGroup={1}
            threshold={'150px'}
            initialSlide={0}
          >
            {items.map((slide) => (
              <SwiperSlide key={slide.id}>
                <div className="vertical-carousel__slide">
                  <div className="vertical-carousel__slide-image">
                    <Image media={slide.fields.image} />
                  </div>
                  <div className="vertical-carousel__slide-content">
                    <div className="container">
                      <div className="vertical-carousel__slide-content--inner">
                        <h3>{slide.fields.subtitle.value}</h3>
                        <h2>{slide.fields.title.value}</h2>
                        <RichText field={slide.fields.description} />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );

  const renderMobile = (
    <>
      <Accordion allowZeroExpanded>
        {items.map((item) => (
          <AccordionItem
            key={item.id}
            style={{ backgroundImage: `url(${item.fields.image.value.src})` }}
          >
            <AccordionItemHeading>
              <AccordionItemButton>
                <span className="accordion__button--collapse">{item.fields.subtitle.value}</span>
                <span className="accordion__button--collapse">{plusIcon}</span>
                <span className="accordion__button--expand">{item.fields.title.value}</span>
                <span className="accordion__button--expand">{minusIcon}</span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <RichText field={item.fields.description} />
            </AccordionItemPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );

  return (
    <section className="vertical-carousel">
      <>
        {isDesktop && renderDesktop}
        {!isDesktop && renderMobile}
      </>
    </section>
  );
};

VerticalCarousel.propTypes = propTypes;

export default withSitecoreContext()(VerticalCarousel);

const minusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_1618_3018"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1618_3018)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
  </svg>
);

const plusIcon = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="14.5" stroke="white" />
    <mask
      id="mask0_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M15.75 14.25V8.25H14.25V14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_517_10254)">
      <path d="M15.75 14.25H14.25H8.25V15.75H14.25H15.75H21.75V14.25H15.75Z" fill="white" />
    </g>
    <mask
      id="mask1_517_10254"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="8"
      width="14"
      height="14"
    >
      <path
        d="M14.25 14.25H8.25V15.75H14.25V21.75H15.75V15.75H21.75V14.25H15.75V8.25H14.25V14.25Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1_517_10254)">
      <path d="M14.25 14.25V15.75V21.75H15.75V15.75V14.25V8.25H14.25V14.25Z" fill="white" />
    </g>
  </svg>
);
