import { Text, Image, Link, RichText } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const SmallCTA = ({ fields }) => {
  const ctaClass = fields.FlipAlignment.value
    ? 'contact__container contact__container--left'
    : 'contact__container';
  return (
    // To start the images at the left use this class "contact__container--left"
    <>
      <div className={ctaClass}>
        <div className="contact__details">
          <Text tag="h2" field={fields.Title} />
          <RichText field={fields.Details} />
          <Link className="btn btn-secondary btn-rounded" field={fields.Link} />
        </div>
        <div className="contact__Img">
          <Image field={fields.Image} />
        </div>
      </div>
      <div className="contact__border"></div>
    </>
  );
};
export default SmallCTA;
