import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';

const WhiteBox = ({ rendering }) => (
  <div className="white-box">
    <Placeholder name="white-box" rendering={rendering} />
  </div>
);

export default WhiteBox;
