import React, { useState, useEffect } from 'react';
import { Image, Text, RichText, DateField } from '@sitecore-jss/sitecore-jss-react';
import moment from 'moment';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import RelatedNews from '../RelatedNews';
import { withTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import * as Constants from '../../constants';

import { object, func } from 'prop-types';

import ReactGA from 'react-ga4';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

const Article = (props) => {
  const { sitecoreContext, t } = props;

  // Update Months in Arabic
  if (sitecoreContext.language === 'ar') {
    moment.updateLocale('en', {
      months: [
        'يناير',
        'فبراير',
        'مارس',
        'أبريل',
        'مايو',
        'يونيو',
        'يوليو',
        'أغسطس',
        'سبتمبر',
        'أوكتوبر',
        'نوفمبر',
        'ديسمبر',
      ],
    });
  }
  const [copySuccess, setCopySuccess] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const history = useHistory();
  // Check if contain related news
  const hasRelatedNews = sitecoreContext.route.fields.RelatedArticles.length > 0 ? true : false;

  async function copyToClip() {
    await navigator.clipboard.writeText(location.href);
    setCopySuccess('Copied');

    // Track Copy event in Google Analytics
    ReactGA.event({
      category: 'User',
      action: 'Copied article link to share',
      label: 'Share Article',
    });
  }
  useEffect(() => {
    if (copySuccess) {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
        setCopySuccess(false);
      }, 2000);
    }
  });

  return (
    <div className="article">
      <div className="container-bleed article__shadow">
        <img
          className="article__bg"
          src={sitecoreContext.route.fields.Image.value.src || Constants.DEFAULT_IMAGE_URL}
        />
      </div>
      <div className="container-bleed container-bleed__has-curved-bg ">
        <div className="container">
          <div className="article__box">
            <div className="article__date">
              <DateField
                field={sitecoreContext.route.fields.Date}
                render={(date) => moment(date).format('DD MMMM YYYY')}
              />
              {}
            </div>
            <div className="article__title">
              <Text field={sitecoreContext.route.fields.Title} />
            </div>
            <div className="article__desc">
              <RichText field={sitecoreContext.route.fields.NewsContent} />
              {/* This section for the "Letter from CEO" "signature" to handle by majdy as we agreed */}
              {/* <div className="article__signature">
                <span className="article__signature--name">George Tanasijevich</span>
                <span className="article__signature--position">
                  CEO King Salman Park Foundation
                </span>
              </div> */}
              <div>
                <div className="article__actions">
                  <Link
                    to={`/${sitecoreContext.language}/news/`}
                    className="btn btn-secondary-article"
                  >
                    <span>{t('BackToNews')}</span>
                  </Link>
                  <button
                    className="btn btn-link article__actions-share"
                    onClick={() => {
                      copyToClip();
                    }}
                  >
                    {shareIcon}
                    <span>{props.t('ShareArticle')}</span>
                    <span className={`${showTooltip ? 'tooltip-show' : 'tooltip-hide'}`}>
                      {t('TextCopied')}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {hasRelatedNews && (
            <div className="article__related-news">
              <RelatedNews relatedNews={sitecoreContext.route.fields.RelatedArticles} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Article.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(Article));

const shareIcon = (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.25 14C15.125 14 14.075 14.525 13.325 15.425L7.85001 11.975C8.07501 11.3 8.07501 10.625 7.85001 10.025L13.325 6.575C14 7.475 15.05 8 16.25 8C18.35 8 20 6.35 20 4.25C20 2.15 18.35 0.5 16.25 0.5C14.15 0.5 12.5 2.15 12.5 4.25C12.5 4.625 12.575 4.925 12.65 5.225L7.17501 8.675C5.90001 7.025 3.50001 6.8 1.92501 8.075C0.275011 9.35 0.0500113 11.75 1.32501 13.325C2.60001 14.975 5.00001 15.2 6.57501 13.925C6.80001 13.775 7.02501 13.55 7.17501 13.325L12.65 16.775C12.575 17.075 12.5 17.45 12.5 17.75C12.5 19.85 14.15 21.5 16.25 21.5C18.35 21.5 20 19.85 20 17.75C20 15.65 18.35 14 16.25 14ZM16.25 2C17.525 2 18.5 2.975 18.5 4.25C18.5 5.525 17.525 6.5 16.25 6.5C14.975 6.5 14 5.525 14 4.25C14 2.975 15.05 2 16.25 2ZM4.25001 13.25C2.97501 13.25 2.00001 12.275 2.00001 11C2.00001 9.725 2.97501 8.75 4.25001 8.75C5.52501 8.75 6.50001 9.725 6.50001 11C6.50001 12.275 5.52501 13.25 4.25001 13.25ZM16.25 20C14.975 20 14 19.025 14 17.75C14 16.475 14.975 15.5 16.25 15.5C17.525 15.5 18.5 16.475 18.5 17.75C18.5 19.025 17.525 20 16.25 20Z"
      fill="#414855"
    />
  </svg>
);
