import React from 'react';
import { Link } from 'react-router-dom';

const SiteMap = ({ fields }) => {
  return (
    <div className="sitemap-content">
      <ul>
        {fields.items.map((item) => (
          <li key={item.id}>
            {item.showLink ? <Link to={item.url}>{item.title}</Link> : <span>{item.title}</span>}
            {item.children && (
              <ul>
                {item.children.map((child) => (
                  <li key={child.id}>
                    {child.showLink ? (
                      <Link to={child.url}>{child.title}</Link>
                    ) : (
                      <span>{child.title}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SiteMap;
