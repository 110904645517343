import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { createDefaultFieldFactory, FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import * as Constants from '../../constants.js';

export const AllFieldTypes = {
  ...FieldTypes,
  GoogleRecaptcha: '{E59873D0-5843-4AA8-BEA4-681D1CFAC1DA}',
};
const CustomFieldFactory = createDefaultFieldFactory();
CustomFieldFactory.setComponent(AllFieldTypes.GoogleRecaptcha, ({ field }) => {
  const recaptcha = useRef(null);
  const [isVerified, setIsVerified] = useState(false);
  const [token, setToken] = useState(''); // Fix typo
  const [errorMessage, setErrorMessage] = useState(false);
  const handleRecaptchaChange = async (value) => {
    if (value) {
      setErrorMessage(false);
      setToken(value);
      setIsVerified(!!token);
    } else {
      setErrorMessage(true);
    }
  };
  useEffect(() => {
    let formButton;
    if (recaptcha.current) {
      formButton = recaptcha.current?.captcha?.parentElement?.querySelector('[type="submit"]');
    }

    if (formButton) {
      formButton.addEventListener('click', (e) => {
        const captchaValue = recaptcha.current?.getValue();
        if (!captchaValue || captchaValue.length === 0) {
          setErrorMessage(true);
          recaptcha.current?.reset();
          e.preventDefault();
        }
      });
    }
  }, []);

  return (
    <>
      <ReCAPTCHA
        sitekey={field?.model?.publicKey}
        onChange={handleRecaptchaChange}
        ref={recaptcha}
        onExpired={() => {
          setIsVerified(false);
          setToken('');
          setIsVerified(false);
          setErrorMessage(true);
        }}
      />
      <input type="hidden" className="recaptcha-hidden" id="hdntoken" value={token} />
      {errorMessage && <span style={{ color: 'red' }}>{field?.model?.errorMessage}</span>}
    </>
  );
});

const buttonItemId = '{7CE25CAB-EF3A-4F73-AB13-D33BDC1E4EE2}';
CustomFieldFactory.setComponent(buttonItemId, (props) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const recaptchaVerification = async (recaptchaToken) => {
    try {
      const formData = { recaptchaToken };
      const response = await axios.post('/api/form/submit', formData);
      return response.data.success;
    } catch (error) {
      return false;
    }
  };

  async function onButtonClick(e) {
    var form = document.querySelector('form');
    if (form) {
      const tokenElement = document.getElementById('recaptcha-token');
      if (form && form.getElementsByClassName('recaptcha-hidden').length > 0) {
        if (!!form.getElementsByClassName('recaptcha-hidden')[0].value) {
          if (
            await recaptchaVerification(form.getElementsByClassName('recaptcha-hidden')[0].value)
          ) {
            props.onButtonClick(props?.field?.buttonField?.name);
          }
        } else {
          e.preventDefault();
          if (Constants.getLocaleFromUrl() === 'ar') {
            alert('مطلوب إعادة التحقق.');
          } else {
            alert('Recaptcha is required.');
          }
          return false;
        }
      }
    }
  }

  return (
    <>
      <button
        type="submit"
        value={props?.field?.model?.title}
        name={props?.field?.buttonField?.name}
        id={props?.field?.buttonField?.id}
        onClick={onButtonClick}
        className="btn btn-secondary btn-rounded"
      >
        {props?.field?.model?.title}
      </button>
    </>
  );
});

export default CustomFieldFactory;
