import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import config from '../../temp/config';
import { Link } from 'react-router-dom';
import NewsItem from '../NewsItem';
import { useQuery } from 'react-query';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';
// Import Swiper styles
import '../../assets/sass/components/_swiper.scss';
import { object, func } from 'prop-types';

const propTypes = {
  sitecoreContext: object.isRequired,
  t: func.isRequired,
};

const LatestNewsHome = (props) => {
  const { sitecoreContext, t } = props;

  const fetchLatestNews = async () => {
    let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${sitecoreContext.language}&$filter=TemplateName eq 'News Article'&$orderby=FieldValues/Date desc&$expand=FieldValues&$top=4`;

    const request = await fetch(url);

    let result = await request.json();

    return result.value;
  };

  const latestNews = useQuery('newsHome', fetchLatestNews, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return (
    <div className="latest-news latest_NewsHome">
      <div
        className="latest-news__header"
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="500"
        data-aos-duration="500"
      >
        <h2>{t('LatestNews')}</h2>
        <Link className="latest-news__header--all-news" to={`/${sitecoreContext.language}/news/`}>
          <span>{t('SeeAllNews')}</span>
        </Link>
      </div>

      <Swiper
        style={{ '--swiper-pagination-bullet-horizontal-gap': '10px' }}
        className="latest-news__wrapper"
        key={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
        dir={sitecoreContext.language === 'ar' ? 'rtl' : 'ltr'}
        spaceBetween={20}
        modules={[Pagination, Autoplay, Navigation]}
        pagination={{
          clickable: 'true',
          type: 'bullets',
          renderBullet: function (index, className) {
            return `<span class="${className}"><i></i><b></b></span>`;
          },
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        freeMode={true}
        slidesPerView={1.15}
        centeredSlides={true}
        slidesPerGroup={1}
        breakpoints={{
          768: {
            freeMode: false,
            slidesPerView: 2,
            centeredSlides: false,
            slidesPerGroup: 2,
          },
          1024: {
            freeMode: false,
            slidesPerView: 3,
            centeredSlides: false,
            slidesPerGroup: 3,
          },
        }}
        // navigation={true} // to enable navigation
        navigation={{
          prevEl: '.latest-news-button-prev',
          nextEl: '.latest-news-button-next',
        }}
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-delay="750"
        data-aos-duration="500"
      >
        {latestNews.data?.map((newsItem) => (
          <SwiperSlide key={newsItem.Id}>
            <NewsItem data={newsItem.FieldValues} key={newsItem.Id} url={newsItem.Path} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="latest-news-button-prev swiper-button-prev" aria-label="Previous slide"></div>
      <div className="latest-news-button-next swiper-button-next" aria-label="Next slide"></div>
    </div>
  );
};

LatestNewsHome.propTypes = propTypes;

export default withTranslation()(withSitecoreContext()(LatestNewsHome));
