import React, { useEffect, useMemo, useState } from 'react';
import config from '../../temp/config';
import { withSitecoreContext, DateField } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Pager from '../Pager';

const LatestEvents = (props) => {
  const eventsPerPage = 6;
  const [latestEvents, setLatestEvents] = useState([]);

  useEffect(() => {
    async function fetchAllEvents() {
      let url = `${config.sitecoreApiHost}/sitecore/api/ssc/aggregate/content/Items?sc_apikey=${config.sitecoreApiKey}&language=${props.sitecoreContext.language}&$filter=TemplateName eq 'Event'&$orderby=FieldValues/dateFrom desc&$expand=FieldValues`;
      const request = await fetch(url);
      let result = await request.json();

      result = result.value;
      setLatestEvents(result);
    }
    fetchAllEvents();
  }, []);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const currentEventsData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * eventsPerPage;
    const lastPageIndex = firstPageIndex + eventsPerPage;
    return latestEvents.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, latestEvents]);

  return (
    <>
      <div className="event-container">
        <div className="event-list">
          {currentEventsData.map((item) => (
            <div className="events container" key={item.Id}>
              <div className="events__box">
                <Link
                  to={item.Path.replace(
                    '/sitecore/content/ksp/home',
                    `/${props.sitecoreContext.language}`
                  ).toLowerCase()}
                >
                  <h2 className="events__title">{item.FieldValues.title}</h2>
                </Link>
                <div className="events__row">
                  <span className="events__date">
                    {moment(item.FieldValues.dateFrom).format('MMMM DD, YYYY')}
                    {'  -  '}
                    {moment(item.FieldValues.dateTo).format('MMMM DD, YYYY')}
                  </span>
                  <span className="events__location-green">{item.FieldValues.location}</span>
                </div>
                <div className="events__desc">{item.FieldValues.shortdescription}</div>
                <div className="events__actions">
                  <Link
                    className="events__actions--btnBook btn btn-secondary"
                    to={item.Path.replace(
                      '/sitecore/content/ksp/home',
                      `/${props.sitecoreContext.language}`
                    ).toLowerCase()}
                  >
                    {props.t('EventDetails')}
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="container">
          <Pager
            currentPage={currentPage}
            totalCount={latestEvents.length}
            pageSize={eventsPerPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(withSitecoreContext()(LatestEvents));
