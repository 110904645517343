import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import { shape, object } from 'prop-types';

const propTypes = {
  fields: shape({
    kingSalmanPic: object.isRequired,
    kingSalmanDesc: object.isRequired,
    kingSalmanTitle: object.isRequired,
    princeMohammadPic: object.isRequired,
    princeMohammadDesc: object.isRequired,
    princeMohammadTitle: object.isRequired,
    bottomText: object.isRequired,
  }).isRequired,
};

const Testimonials = ({ fields }) => {
  return (
    <section className="testimonials">
      <div className="container">
        <div className="testimonials__wrapper">
          <div className="testimonials__king">
            <div
              className="testimonials__img"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="750"
              data-aos-duration="1000"
            >
              <img src={fields.kingSalmanPic.value.src} alt={fields.kingSalmanTitle.value} />
            </div>
            <div
              className="testimonials__qoute"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1000"
              data-aos-duration="1000"
            >
              <RichText field={fields.kingSalmanDesc} />
            </div>
            <RichText
              tag="span"
              field={fields.kingSalmanTitle}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1250"
              data-aos-duration="950"
            />
          </div>

          <div className="testimonials__prince">
            <div
              className="testimonials__img"
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="750"
              data-aos-duration="1000"
            >
              <img
                src={fields.princeMohammadPic.value.src}
                alt={fields.princeMohammadTitle.value}
              />
            </div>
            <div className="testimonials__qoute">
              <RichText
                field={fields.princeMohammadDesc}
                data-aos="fade-up"
                data-aos-once="true"
                data-aos-delay="1000"
                data-aos-duration="1000"
              />
            </div>
            <RichText
              tag="span"
              field={fields.princeMohammadTitle}
              data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1250"
              data-aos-duration="950"
            />
          </div>
        </div>
        <RichText
          field={fields.bottomText}
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay="1250"
          data-aos-duration="950"
        />
      </div>
    </section>
  );
};

Testimonials.propTypes = propTypes;

export default Testimonials;
